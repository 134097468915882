<script setup lang="ts">
import { useRoute } from 'vue-router';

const route = useRoute();
</script>

<template>
	<div id="appLayout" :class="$style.appLayout">
		<!-- <HeaderWithSafeArea /> -->
		<Component :is="route.meta.layoutComponent">
			<slot />
		</Component>
	</div>
</template>

<style lang="scss" module>
.appLayout {
	width: 100%;
}
</style>
